@tailwind base;
@tailwind components;
@tailwind utilities;

@custom-variant dark (&:is(.dark *));

@layer utilities {
  .custom-transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 0.46 !important;
    --tw-scale-y: 0.48 !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .video-custom-transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 0.35 !important;
    --tw-scale-y: 0.34 !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none !important;
    scrollbar-width: none !important;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .text-gradient {
    background: linear-gradient(92deg, #9924fe -14.92%, #fe7b92 135.68%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;

    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --primary: linear-gradient(90deg, #845ed5 0%, #673fba 111.58%);
    --primary-foreground: 0 0% 98%;

    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;

    --radius: 0.5rem;
    --text-color: #343434;
    --text-medium: #373737;
    --gray-background: #f6f6f6;
    --card-background: #f0f0f0;
    --light-background: #fdfcff;
    --light-gray-background: #f9f9f9;
    --muted-text-color: #747474;
    --text-xxs: 0.625rem;
    --skeleton-background: #efefef;
    --light-primary: #f6f1fc;
    --text-url: #3658ec;
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  .dark {
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;

    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;

    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;

    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;

    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --seperator-border-stroke: 220, 26%, 93%, 1;

    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;

    --radius: 0.5rem;
    --text-color: #343434;
    --text-medium: #373737;
    --gray-background: #f6f6f6;
    --card-background: #f0f0f0;
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}

.metricsLineHeight,
.metricsLineHeight *,
.metricsLineHeight b,
.metricsLineHeight strong,
.metricsLineHeight i,
.metricsLineHeight span {
  @apply !leading-[1.4];
}

.editorTextDisplay br,
.editorTextDisplay * br,
.richTextEditor br,
.richTextEditor * br {
  display: block;
  content: "";
  height: 16px;
  /* Adjust this value as needed */
}

.richTextEditor,
.richTextEditor *,
.richTextEditor b,
.richTextEditor strong,
.richTextEditor i,
.richTextEditor span {
  @apply !text-black;
}

.editorTextDisplay font[size="1"] {
  line-height: 1.8 !important;
}

.editorTextDisplay font[size="2"] {
  line-height: 1.8 !important;
}

.editorTextDisplay font[size="3"] {
  line-height: 1.8 !important;
}

.editorTextDisplay font[size="4"] {
  line-height: 1.8 !important;
}

.editorTextDisplay font[size="5"] {
  line-height: 1.8 !important;
}

.editorTextDisplay font[size="6"] {
  line-height: 1.4 !important;
}

.editorTextDisplay font[size="7"] {
  line-height: 1.3 !important;
}

/* Hide .editorTextDisplay when it contains only a div with a br */
.editorTextDisplay:only-child > div:only-child:not(:empty):not(:has(*:not(br))) {
  display: none;
}

/* Hide .editorTextDisplay when it contains only a br */
.editorTextDisplay:only-child > br:only-child {
  display: none;
}

/* Hide the .editorTextDisplay itself in both cases */
.editorTextDisplay:has(> div:only-child:not(:empty):not(:has(*:not(br)))),
.editorTextDisplay:has(> br:only-child) {
  display: none;
}

.coverPageTitle {
  @apply !text-[32px] !text-white !font-bold focus:outline-none hyphens-auto border-border rounded-sm mb-4 overflow-auto leading-[1.2];
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  width: 100%;
}

.coverPageTitle * {
  @apply !text-[32px] !text-white !font-bold focus:outline-none hyphens-auto border-border rounded-sm mb-4 overflow-auto leading-[1.2];
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  width: 100%;
}

[data-state="active"] .gradient-border {
  display: block;
}

.cover-padding {
  padding: 2.5rem 2.5rem;

  @media (max-width: 768px) {
    padding: 1rem 0;
  }
}

.common-padding {
  padding: 2rem 2.5rem;

  @media (max-width: 768px) {
    padding: 1rem 0;
  }
}

.css-15yzd3w {
  filter: drop-shadow(rgba(0, 0, 0, 0) 0px 0px 0px);
  transition: filter 0.15s ease 0s;
}

.css-yz8w9z {
  width: 3em;
  height: 3em;
  flex-shrink: 0;
  border: 0px;
  background: transparent;
  outline-offset: -2px;
}

.aspect-ratio-16-9 {
  position: relative;
  width: 100%;
}

.aspect-ratio-16-9::before {
  content: "";
  display: block;
  padding-top: 56.25%;
  /* 9 / 16 = 0.5625 */
}

.aspect-ratio-16-9 > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.aspect-w-16 {
  aspect-ratio: 16 / 9;
}

.aspect-h-9 {
  aspect-ratio: 16 / 9;
}

/* For browsers that do not support aspect-ratio property */
.aspect-w-16.aspect-h-9 {
  position: relative;
  width: 100%;
}

.aspect-w-16.aspect-h-9::before {
  content: "";
  display: block;
  padding-top: 56.25%;
  /* 16:9 aspect ratio (9/16 = 0.5625) */
}

.aspect-w-16.aspect-h-9 > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

:root {
  --background: #fbfbfb;
  /* Default background color for other devices */
}

@media (max-width: 767px) {
  :root {
    --background: #ffffff;
    /* Background color for mobile devices */
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeIn {
  animation: fadeIn 0.3s ease-out forwards;
}

.get-details-div {
  width: 80vw;
  height: 50vh;
  flex-shrink: 0;
  position: absolute;
  top: -70%;
  left: 50%;
  transform: translateX(-50%) rotate(179.741deg);
  filter: blur(26.65px);
  border-radius: 50%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 15.18%, #fff 85.82%);
  opacity: 1;
  z-index: 40;
}

@media (min-width: 1080px) {
  .get-details-div {
    width: 1234.617px;
    height: 845.154px;
    border-radius: 1234.617px;
  }
}

:root {
  --background: #fbfbfb;
  /* Default background color for other devices */
}

@media (max-width: 767px) {
  :root {
    --background: #ffffff;
    /* Background color for mobile devices */
  }
}

.cropBackground {
  background-color: #343434;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #eaeaea;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: #eaeaea;
}

#case-studies-slide-preview:-webkit-full-screen {
  overflow: auto !important;
  height: 100vh !important;
}

#case-studies-slide-preview:-moz-full-screen {
  overflow: auto !important;
  height: 100vh !important;
}

#case-studies-slide-preview:-ms-fullscreen {
  overflow: auto !important;
  height: 100vh !important;
}

#case-studies-slide-preview:fullscreen {
  overflow: auto !important;
  height: 100vh !important;
}

.grainy {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwBAMAAAClLOS0AAAAElBMVEUAAAD8/vz08vT09vT8+vzs7uxH16TeAAAAAXRSTlMAQObYZgAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAuFJREFUOI0Vk+3NLiEIRG1B8ClAYAsQ2AIEt4D9ePtv5Xp/mZgYJ2fOFJKEfInkVWY2aglmQFkimRTV7MblYyVqD7HXyhKsSuPX12MeDhRHLtGvRG+P+B/S0Vu4OswR9tmvwNPyhdCDbVayJGads/WiUWcjCvCnruTBNHS9gmX2VzVbk7ZvB1gb1hkWFGl+A/n+/FowcO34U/XvKqZ/fHY+6vgRfU92XrOBUbGeeDfQmjWjdrK+frc6FdGReQhfSF5JvR29O2QrfNw1huTwlgsyXLo0u+5So82sgv7tsFZR2nxB6lXiquHrfD8nfYZ9SeT0LiuvSoVrxGY16pCNRZKqvwWsn5OHypPBELzohMCaRaa0ceTHYqe7X/gfJEEtKFbJpWoNqO+aS1cuTykGPpK5Ga48m6L3NefTr013KqYBQu929iP1oQ/7UwSR+i3zqruUmT84qmhzLpxyj7pr9kg7LKvqaXxZmdpn+6o8sHqSqojy02gU3U8q9PnpidiaLks0mbMYz+q2uVXsoBQ8bfURULYxRgZVYCHMv9F4OA7qxT2NPPpvGQ/sTDH2yznKh7E2AcErfcNsaIoN1izzbJiaY63x4QjUFdBSvDCvugPpu5xDny0jzEeuUQbcP1aGT9V90uixngTRLYNEIIZ6yOF1H8tm7rj2JxiefsVy53zGVy3ag5uuPsdufYOzYxLRxngKe7nhx3VAq54pmz/DK9/Q3aDam2Yt3hNXB4HuU87jKNd/CKZn77Qdn5QkXPfqSkhk7hGOXXB+7v09KbBbqdvxGqa0AqfK/atIrL2WXdAgXAJ43Wtwe/aIoacXezeGPMlhDOHDbSfHnaXsL2QzbT82GRwZuezdwcoWzx5pnOnGMUdHuiY7lhdyWzWiHnucLZQxYStMJbtcydHaQ6vtMbe0AcDbxG+QG14AL94xry4297xpy9Cpf1OoxZ740gHDfrK+gtsy0xabwJmfgtCeii79B6aj0SJeLbd7AAAAAElFTkSuQmCC);
}

@keyframes spin-slow {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-spin-slow {
  animation: spin-slow 3s linear infinite;
}

.animate-fade-in {
  animation: fade-in 0.3s ease-out forwards;
}
